import { useState, useEffect } from 'react'
import '../../styles/Contest.css'
import { useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import axios from 'axios'
import Server from '../../Server'
// import locm from '../../data/Locm'

const Addcntst = () => {
    const [vl, setVl] = useState()
    const [usr, setUsr] = useState()
    const [sttm, setSttm] = useState()
    let name, value
    const navig = useNavigate()

    const fetchUsr = async () => {
        try {
            const token = localStorage.getItem('user')
            if (token) {
                const res = await axios.get(`${Server}/user/sess/`+token)
                const dta = await res.data
                // console.log(dta)

                if (dta.statuscode === 220) {
                    // console.log(dta.success)
                    setUsr(dta.user)
                }
                else {
                    console.warn(dta.error)
                    setUsr(null)
                    localStorage.removeItem('user')
                }
            }
            else {
                navig('/admin/login')
            }
        } catch (error) {
            console.error(error)
        }
    }
    const fetchStatem = async () => {
        try {
            const res = await axios.get(`${Server}/statem/view`)
            const dta = await res.data
            // console.log(dta)
            setSttm(dta.data)
        } catch (error) {
            console.error(error)
        }
    }
    useEffect(() => {
        fetchUsr()
        fetchStatem()
        document.querySelector('#sttsec').style.display = 'none'
    }, [])

    const hndlinp = (e) => {
        name = e.target.name
        value = e.target.value
        setVl({...vl, [name]:value})

        if (String(e.target.name) === 'cntst_level') {
            if ((String(e.target.value)==='Regional')||(String(e.target.value)==='Zonal')) {
                document.querySelector('#sttsec').style.display = 'flex'
            }
        }
    }
    const hndlsub = async (e) => {
        e.preventDefault()
        const {cntst_name, cntst_level, state, cntst_start_dt, cntst_validity, scheme_name} = vl
        const cntstdt = new Date(cntst_start_dt).toISOString()
        // console.log(cntstdt)

        try {
            const res = await axios.post(`${Server}/contest/add`, {cntst_name, cntst_level, state, cntst_start_dt:cntstdt, cntst_validity, scheme_name, added_by:usr&&usr._id})
            const dta = await res.data
            // console.log(dta.data)

            if (dta.statuscode === 220) {
                window.alert(dta.success)
                navig('/admin/contests')
            }
            else {
                window.alert(dta.error)
            }
        } catch (error) {
            console.error(error)
        }
    }

  return (
    <>
        <div className="wbpg">
            <Header/>

            <div className="main container">
                <div className='content' id='cntstsec'>
                    <div className="hdr">Add Contest</div>
                    <div className="frmsec">
                        <form className='frm'>
                            <div className='frmgrp'>
                                <label htmlFor="cntst_name" className="frmlbl form-label w-25">Contest Name<span className='mandtry'>&nbsp;&nbsp;*</span></label>
                                <input type="text" className="frminp form-control form-control-lg w-50" name="cntst_name" id="cntst_name" onChange={hndlinp} />
                                <div className="frmvald"></div>
                            </div>
                            <div className='frmgrp'>
                                <label htmlFor="cntst_level" className="frmlbl form-label w-25">Contest Level<span className='mandtry'>&nbsp;&nbsp;*</span></label>
                                <select className='frmslct form-select form-select-lg w-50' name="cntst_level" id="cntst_level" onChange={hndlinp}>
                                    <option value="0">Select Level</option>
                                    <option value="Local">Local</option>
                                    <option value="Regional">Regional</option>
                                    <option value="Zonal">Zonal</option>
                                    <option value="National">National</option>
                                    <option value="Global">Global</option>
                                </select>
                                <div className="frmvald"></div>
                            </div>
                            <div className='frmgrp' id='sttsec'>
                                {
                                    vl&&((String(vl.cntst_level)==='Regional')||(String(vl.cntst_level)==='Zonal'))&&(
                                        <>
                                            <label htmlFor="state" className="frmlbl form-label w-25">Area</label>
                                            <select className='frmslct form-select form-select-lg w-50' name="state" id="state" onChange={hndlinp}>
                                                <option value="0">Select Location</option>
                                                {
                                                    sttm&&sttm.map((elm, i) => (
                                                        <option value={elm._id} key={i}>{elm.state_name}</option>
                                                    ))
                                                }
                                            </select>
                                            <div className="frmvald"></div>
                                        </>
                                    )
                                }
                            </div>
                            <div className='frmgrp'>
                                <label htmlFor="cntst_start_dt" className="frmlbl form-label w-25">Contest Start Date<span className='mandtry'>&nbsp;&nbsp;*</span></label>
                                <input type="date" className="frminp form-control form-control-lg w-50" name="cntst_start_dt" id="cntst_start_dt" onChange={hndlinp} />
                                <div className="frmvald"></div>
                            </div>
                            <div className='frmgrp'>
                                <label htmlFor="cntst_validity" className="frmlbl form-label w-25">Contest Validity (in Days)</label>
                                <input type="text" className="frminp form-control form-control-lg w-50" name="cntst_validity" id="cntst_validity" onChange={hndlinp} />
                                <div className="frmvald"></div>
                            </div>
                            <div className='frmgrp'>
                                <label htmlFor="scheme_name" className="frmlbl form-label w-25">Scheme Name</label>
                                <input type="text" className="frminp form-control form-control-lg w-50" name="scheme_name" id="scheme_name" onChange={hndlinp} />
                                <div className="frmvald"></div>
                            </div>
                            <div className="frmbtngrp gap-5">
                                <input className='btn btn-outline-primary btn-lg mbtn' type="submit" value="Add" onClick={hndlsub} />
                                <input className='btn btn-outline-secondary btn-lg mbtn' type="reset" value="Refresh" onClick={(e) => {
                                    if (document.querySelector('#sttsec')) {
                                        document.querySelector('#sttsec').style.display = 'none'
                                    }
                                    document.querySelector('#cntst_level').value = 0
                                }} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    </>
  )
}

export default Addcntst