import React from 'react'
import { Bar } from 'react-chartjs-2'

const BarChart = ({labelset, dataset, lbltxt, width, height}) => {
    const chartdata = {
        labels: labelset,
        datasets: [{
            type: 'bar',
            label: lbltxt,
            data: dataset,
            backgroundColor: ['rgba(102, 153, 255, 0.3)'],
            borderColor: ['rgb(102, 153, 255)'],
            borderWidth: 1,
            maxBarThickness: 30
        }]
    }
    const options = {
        responsive: true,
        plugins: {
            tooltip: {
                enabled: true,
                backgroundColor: '#8c8cd9',
                titleColor: '#22225e',
                titleFont: {
                    size: '13%',
                    weight: '900'
                },
                bodyColor: '#4040bf'
            }
        },
        scales: {
            x: {
                stacked: true
            },
            y: {
                stacked: true,
                ticks: {
                    // min: 0,
                    stepSize: 10
                }
            }
        },
        layout: {
            padding: 50
        }
    }
    
  return (
    <>
        <Bar data={chartdata} options={options} width={width} height={height} />
    </>
  )
}

export default BarChart