import React, { useEffect, useRef, useState } from 'react'
import FlipCard from './FlipCard';
import light2slow from '../../images/lckydrwimg/light2slow.mp4'
import light2fast from '../../images/lckydrwimg/light2fast.mp4'
import closeBtn from '../../images/newGifts/Button02.png'
import axios from 'axios';
import Server from '../../Server';
import light_animation_fast_new from '../../images/newGifts/SLOT_machine_light.gif'
import rflbg from '../../audios/Sounds/spin2.mp3'
import wnnrflp from '../../audios/Sounds/wnnr_reveal.mp3'
import bgaud from '../../audios/Sounds/Bgsound.mp3'


const LuckyDrawCounters = ({userSelectedGift,flagZeroData,handleBackHome,getWinnersData}) => {
    
    const [maxScreens, setmaxScreens] = useState(userSelectedGift?.prz_itm?.max_screen_limit);
    const [maxCounters, setmaxCounters] = useState(flagZeroData.length);


    const [counterValue1, setcounterValue1] = useState('000000');
    const [counterValue2, setcounterValue2] = useState('000000');
    const [counterValue3, setcounterValue3] = useState('000000');
    const [counterValue4, setcounterValue4] = useState('000000');
    const [counterValue5, setcounterValue5] = useState('000000');
    const [counterValue6, setcounterValue6] = useState('000000');
    const counterRef1 = useRef(null)
    const counterRef2 = useRef(null)
    const counterRef3 = useRef(null)
    const counterRef4 = useRef(null)
    const counterRef5 = useRef(null)
    const counterRef6 = useRef(null)
    let counterMeta = [
        {
            ref: counterRef1,
            val: counterValue1
        },
        {
            ref: counterRef2,
            val: counterValue2
        },
        {
            ref: counterRef3,
            val: counterValue3
        },
        {
            ref: counterRef4,
            val: counterValue4
        },
        {
            ref: counterRef5,
            val: counterValue5
        },
        {
            ref: counterRef6,
            val: counterValue6
        }
        
    ]
    let rflaudio = new Audio(rflbg)
    let wnnrflpaudio = new Audio(wnnrflp)
    let bgaudio = new Audio(bgaud)

    useEffect(() => {
        setTimeout(() => {
            rflaudio.play();
            rflaudio.loop = true
        }, 500);
        setTimeout(() => {
            rflaudio.pause()
            wnnrflpaudio.play();
            wnnrflpaudio.loop = true
        }, 10500);
        setTimeout(() => {
            wnnrflpaudio.pause()
            bgaudio.play();
            bgaudio.loop = true
        }, 23000);
        
    }, [])

    useEffect(() => {
        updateFlagOne() 
    }, [])

    const updateFlagOne = async()=>{

        let winnerIds = []

        if(maxScreens <= maxCounters){
            Array.from({ length: maxScreens}, (_, i) => (
                winnerIds.push(flagZeroData[i]?._id)
            ))
        }
        if(maxScreens > maxCounters){
            Array.from({ length: maxCounters}, (_, i) => (
                winnerIds.push(flagZeroData[i]?._id)
            ))
        }
        if(winnerIds.length > 0){
            const res = await axios.post(`${Server}/winner/edit/flag`,{wnnr:winnerIds, wnnrflag:1 });            
        }
    }
    

  return (
    <div className="CounterHolder">
         <div className="mainRaffleCountdowns">
            <div className={`countDownArea ${userSelectedGift?.prz_itm?.max_screen_limit == 1? 'fullWidthCounter':''} ${maxScreens > maxCounters && maxCounters == 1? 'fullWidthCounter':''} `}>
                {maxScreens <= maxCounters && Array.from({ length: userSelectedGift?.prz_itm?.max_screen_limit }, (_, i) => (
                    <>
                        <FlipCard key={i} wnnr_acc={flagZeroData[i]?.wnnr_acc} val={flagZeroData[i]?.wnnr_tckt?.tckt_no} reff={counterMeta[i].ref} light2fast={light2fast} />        
                        
                    </>
                ))}  
                {maxScreens > maxCounters && Array.from({ length: maxCounters }, (_, i) => (
                    <>
                        <FlipCard key={i} wnnr_acc={flagZeroData[i]?.wnnr_acc} val={flagZeroData[i]?.wnnr_tckt?.tckt_no} reff={counterMeta[i].ref} light2fast={light2fast} />        
                        
                    </>
                ))}  
                {Array.from({ length: (6 - maxCounters) }, (_, i) => (
                    <>
                    
                    <div className="countdownItem blank">
                        <div className="coundDownContent">
                           
                        </div>
                        {/* <video src={light_animation_fast_new} autoPlay loop muted></video> */}
                        <img src={light_animation_fast_new} alt="vid" />
                    </div>
                    </>
                ))}                                      
            </div>
            <div className="ctoArea">
                <button id="goBackToHome" onClick={(e) => {
                    e.preventDefault();
                    rflaudio.muted = true
                    rflaudio.pause()
                    wnnrflpaudio.muted = true
                    wnnrflpaudio.pause()
                    bgaudio.muted = true
                    bgaudio.pause()
                    handleBackHome()
                }}>
                <img src={closeBtn} onClick={getWinnersData} alt='imag'/>
                </button>
            </div>
        </div>
    </div>
  )
}

export default LuckyDrawCounters