import { useState, useEffect, useRef } from 'react'
import { NavLink, useParams, useNavigate } from 'react-router-dom'
import { FaUserPlus, FaCloudUploadAlt, FaUserEdit, FaUserMinus } from 'react-icons/fa'
import { MdCasino, MdSpaceDashboard, MdChangeCircle, MdGroupRemove } from 'react-icons/md'
import { PiGameControllerFill } from "react-icons/pi";
import { RxReset } from 'react-icons/rx'
import Header from '../components/Header'
import Footer from '../components/Footer'
import axios from 'axios'
import Server from '../Server'
// import Utils from '../data/Utils.js'

const Home = () => {
  const [cntst, setCntst] = useState()
  const [wnnr, setWnnr] = useState()
  const counterRef = useRef(null)
  const navig = useNavigate()

  const vwData = async () => {
    try {
      const res = await axios.get(`${Server}/contest/view`)
      const dta = await res.data.data
      // console.log(dta)
      setCntst(dta)
    } catch (error) {
      console.error(error)
    }
  }
  const tcktstat = async () => {
    try {
      const res = await axios.get(`${Server}/ticket/view`)
      const dta = await res.data
      const bookd = dta.data.filter((elm) => {
        return (String(elm.tckt_status)==='Booked')
      })
      console.log(bookd.length);
      const won = dta.data.filter((elm) => {
        return (String(elm.tckt_status)==='Won')
      })
      console.log(won.length);
      const blcked = dta.data.filter((elm) => {
        return (String(elm.tckt_status)==='Blocked')
      })
      console.log(blcked.length);
    } catch (error) {
      console.error(error)
    }
  }
  const getwnr = async () => {
    try {
      const res = await axios.get(`${Server}/winner/view`)
      const dta = await res.data
      console.log(dta)
      if (dta.statuscode === 220) {
        setWnnr(dta.data.sort((a, b) => {
          return (parseInt(a.wn_rank?.rnk_val)-parseInt(b.wn_rank?.rnk_val))
        }))
      }
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    vwData()
    tcktstat()
    getwnr()
  }, [])

  const runallcntst = async (e) => {
    e.preventDefault()

    try {
      if (window.confirm(`Confirm to Run all Contests ?`)) {
        document.querySelector('.autorn').style.display = 'none'
        const res = await axios.get(`${Server}/contest/autorun`)
        const dta = await res.data
        console.log(dta.message);
        if ((dta.statuscode === 220)||(dta.statuscode === 221)) {
          window.alert(dta.message)
          navig(`/admin/winners`)
        }
        else {
          window.alert(dta.message)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const runautorafl = async (e) => {
    e.preventDefault()

    try {
      if (window.confirm(`Confirm to Set Winner Screens ?`)) {
        document.querySelector('.autorn').style.display = 'none'
        document.querySelector('.scrnsettr').style.display = 'none'
        const res = await axios.get(`${Server}/contest/autoscreenset`)
        const dta = await res.data
        console.log(dta);
        if (dta.statuscode === 220) {
          window.alert(dta.message)
          vwData()
          navig(`/admin/winners`)
        }
        else {
          window.alert(dta.message)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const updtallwnnrflgzero = async (e) => {
    e.preventDefault()
    // const {wnnrflag}
    try {
      const res = await axios.post(`${Server}/winner/edit/flagall`, {wnnrflag:0})
      const dta = await res.data
      console.log(dta)
      if (dta.statuscode === 220) {
        window.alert(dta.message)
        vwData()
        navig(`/admin/winners`)
      }
      else {
        window.alert(dta.message)
        vwData()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const hndlresetall = async (e) => {
    e.preventDefault()

    try {
      if (window.confirm(`Confirm to Reset all Contest data?`)) {
        const res = await axios.get(`${Server}/contest/edit/resetall`)
        const dta = await res.data
        console.log(dta)
        if (dta.statuscode === 220) {
          window.alert(dta.message)
          vwData()
          navig(`/admin/contests`)
        }
        else {
          window.alert(dta.error)
          vwData()
        }
      }
      else {
        vwData()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
        <div className="wbpg">
          <Header/>

          <div className="main container">
            <div className="content">
              {/* <div className="hdr">Home</div> */}
              <div className="dtlsec">
                <div className="inf"><span>Here all the Contest details are listed by folllowing table.</span></div>
                <div className="btnsec"><span>Start Autorun All Contests : </span><NavLink className='navlnk'><button className='btn btn-sm btn-info autorn' onClick={runallcntst}>Start All Contest</button></NavLink></div>
                {/* <div className="btnsec"><span>Set Winner Screens & Run Counters : </span><NavLink className='navlnk'><button className='btn btn-sm btn-light scrnsettr' onClick={runautorafl}>Set Screens</button></NavLink></div> */}
                {
                  wnnr&&(wnnr.length>0) ? (
                    <>
                      <div className="btnsec"><span>Set Winner Screens & Run Counters : </span><NavLink className='navlnk'><button className='btn btn-sm btn-light scrnsettr' onClick={runautorafl}>Set Screens</button></NavLink></div>
                      <div className="btnsec"><span>Start Game Raffle : </span><NavLink className='navlnk' to={`/admin/raffle/auto`}><button className='rnbtn'><PiGameControllerFill/></button></NavLink></div>
                      <div className="btnsec"><span>Reset Winner Flags : </span><NavLink className='navlnk'><button className='rstbtn' onClick={updtallwnnrflgzero}><MdChangeCircle /></button></NavLink></div>
                      <div className="btnsec"><span>Reset All Contests : </span><NavLink className='navlnk'><button className='rmvbtn' onClick={hndlresetall}><MdGroupRemove/></button></NavLink></div>
                    </>
                  ) : null
                }
                <div className="btnsec"><span>Check Dashboards : </span><NavLink className='navlnk' to={`/admin/dashboard`}><button className='dashbtn'><MdSpaceDashboard/></button></NavLink></div>
              </div>
            </div>
          </div>

          <Footer/>
        </div>
    </>
  )
}

export default Home