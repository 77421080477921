import React, { useEffect, useState } from 'react'
import Server from '../../Server'

import bike from '../../images/newGifts/BIKE.png'
import bmw from '../../images/newGifts/bmw.png'
import galaxyTab from '../../images/newGifts/GALAXY-TAB.png'
import honda from '../../images/newGifts/HONDA.png'
import tv from '../../images/newGifts/LED-TV.png'
import lightLeft from '../../images/lckydrwimg/light-01.png'
import prizes from '../../images/newGifts/prizes.png'
import tigerLogo2 from '../../images/newGifts/NewLogo-02.png'

import tag from '../../images/newGifts/tag.png'
import Clock from './Clock'
const GiftListsRight = ({giftItems,loading}) => { 
    
  return (
    <div className="raffleRight">
      <div className="imgClock">
        <img src={tigerLogo2} alt="Tiger Logo"/>
        {/* <Clock/> */}
      </div>
                        

      <div className="leftVideoConteiner">
            <div className="contentC">
              <img src={prizes} className="headerImg" alt='imag'/>
            {loading && <div className="loader">Loading... <img src="https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif" alt='imag'/></div>}
            <div className="winnerssListWrap">
                <div className="winnerssList">
            {!loading && giftItems && giftItems.length > 0 && [...giftItems,...giftItems].map((item,index)=>{
              return(
                  <div key={"g"+item._id} className='giftItem'>
                      <img src={tag} className="tag" alt='imag'/>
                      <h5>{item?.prz_itm?.gft_name}</h5>
                      <img src={item?.prz_itm?.gft_img} className='itmimg' alt='imag'/>
                      {/* {item?.prz_itm?.gft_name.toLowerCase().indexOf('bmw') >= 0? <img src={item?.prz_itm?.gft_img} alt='imag'/>:null}
                      {item?.prz_itm?.gft_name.toLowerCase().indexOf('bike') >= 0? <img src={item?.prz_itm?.gft_img} alt='imag'/>:null}
                      {item?.prz_itm?.gft_name.toLowerCase().indexOf('tablet') >= 0? <img src={item?.prz_itm?.gft_img} alt='imag'/>:null}
                      {item?.prz_itm?.gft_name.toLowerCase().indexOf('hyundai') >= 0? <img src={item?.prz_itm?.gft_img} alt='imag'/>:null}
                      {item?.prz_itm?.gft_name.toLowerCase().indexOf('television') >= 0? <img src={item?.prz_itm?.gft_img} alt='imag'/>:null} */}
                      <p>{item?.qty} Items</p>
                  </div>
              )
            })}
            </div>
            </div>
            </div>
            <img src={lightLeft} className="light" alt='imag'/>
        </div>
      
    </div>
  )
}

export default GiftListsRight