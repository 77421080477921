import { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { FaUserPlus, FaCloudUploadAlt, FaUserEdit, FaUserMinus } from 'react-icons/fa'
import Header from '../../../components/Header'
import Footer from '../../../components/Footer'
import axios from 'axios'
import Server from '../../../Server'

const Index = () => {
    const [dtaDet, setDtaDet] = useState()

    const vwData = async () => {
        try {
            const res = await axios.get(`${Server}/orgm/view`)
            const dta = await res.data
            console.log(dta)
            setDtaDet(dta.data)
        } catch (error) {
            console.error(error)
        }
    }
    useEffect(() => {
        vwData()
    }, [])

    const hndldlt = async (id) => {
        try {
            if (window.confirm(`Wants to Remove the Organisation data ?`)) {
                const res = await axios.delete(`${Server}/orgm/remove/`+id)
                const dta = await res.data
                // console.log(data)
                if (dta.statuscode === 220) {
                    window.alert(`State data Successfully Removed.`)
                    vwData()
                }
            }
            else {
                window.alert(`Organisation Removal Cancelled.`)
            }
        } catch (error) {
            console.error(error.message)
        }
    }

  return (
    <>
        <div className="wbpg">
            <Header/>

            <div className="main container">
                <div className='content' id='usrsec'>
                    <div className="hdng">All Organisations Data</div>
                    <div className="dtlsec">
                        <div className="inf"><span>Here all the Organisation details are listed by folllowing table.</span></div>
                        <div className="addsec"><span>To Add Organisation click here : </span><NavLink to="/admin/master/org/add" className='navlnk'><button className='addbtn'><FaUserPlus/></button></NavLink></div>
                        <div className="impsec"><span>For Bulk Organisations Upload : </span><NavLink to='/admin/master/org/upload' className='navlnk'><button className='upldbtn'><FaCloudUploadAlt/></button></NavLink></div>
                    </div>
                    <div className="lst">
                        <span>Existing Organisations list</span>
                        <div className="tbldta">
                            {
                                ((dtaDet === undefined)||(dtaDet === null)||(dtaDet.length === 0)) ? (<div><span>500 ERROR :: Data couldn't be Fetched</span></div>) : (
                                    <table className='tbl table table-striped table-hover'>
                                        <thead>
                                            <tr>
                                                <th>Organisation Name</th>
                                                <th>Organisation Code</th>
                                                <th style={{backgroundColor:'darkslateblue',color:'lightgrey',borderColor:'gray'}}>Edit Data</th>
                                                <th style={{backgroundColor:'darkslateblue',color:'lightgrey',borderColor:'gray'}}>Remove Data</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                dtaDet.map((elm, i) => (
                                                    <tr key={i}>
                                                        <td  style={{whiteSpace:'nowrap'}}>{elm.org_code}</td>
                                                        <td  style={{whiteSpace:'nowrap'}}>{elm.org_desc}</td>
                                                        <td style={{textAlign:'center',whiteSpace:'nowrap'}}><NavLink to={`/editacc/${elm._id}`} className='navlnk'><button className='edtbtn'><FaUserEdit/></button></NavLink></td>
                                                        <td style={{textAlign:'center',whiteSpace:'nowrap'}}><NavLink className='navlnk' onClick={ev => hndldlt(elm._id)}><button className='dltbtn'><FaUserMinus/></button></NavLink></td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
        
        {/* <div className="wbpg">
            <Header/>

            <div className="main container py-5">
                <div className="content" id='orgmsec'>
                    <div className="hdr">Add Organisation</div>
                    <div className="frmsec">
                        <form className='frm'>
                            <div className="frmgrp">
                                <label htmlFor="org_code" className='form-label'>Id</label>
                                <input type="text" name="org_code" id="org_code" className='form-control w-50' onChange={hndlinp} />
                            </div>
                            <div className="frmgrp">
                                <label htmlFor="org_name" className='form-label'>Name</label>
                                <input type="text" name="org_name" id="org_name" className='form-control w-50' onChange={hndlinp} />
                            </div>
                            <div className="frmgrp">
                                <input type="submit" value="Add" className='btn btn-primary btn-lg' onClick={hndlsub} />
                                <input type="reset" value="Refresh" className='btn btn-secondary btn-lg' onClick={(e) => setVl(null)} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <Footer/>
        </div> */}
    </>
  )
}

export default Index