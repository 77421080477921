import React from 'react'
import Slider from 'react-slick'

import banner1 from '../../images/newGifts/LuckyDraw_DiwaliEvent/New folder/banner1.jpg'
import banner2 from '../../images/newGifts/LuckyDraw_DiwaliEvent/New folder/banner2.jpg'

const SliderBanner = () => {
    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1
    };
  return (
    <Slider arrows={false} {...settings}>
        <div className="bannerSliderItem">                              
            <img src={banner1} alt='imag'/>
        </div>  
        <div className="bannerSliderItem">                              
            <img src={banner2} alt='imag'/>
        </div>  
                               
    </Slider>
  )
}

export default SliderBanner