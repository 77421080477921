import { useEffect, useState } from 'react'
import '../../styles/Gift.css'
import { useParams, useNavigate, NavLink } from 'react-router-dom'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import axios from 'axios'
import Server from '../../Server'

const Chngphto = () => {
    const {id} = useParams()
    const [gftnm, setGftnm] = useState()
    const [imgprvw, setImgprvw] = useState()
    const [gftImg, setGftImg] = useState()
    const navig = useNavigate()

    const getdta = async () => {
        try {
            const res = await axios.get(`${Server}/gift/view/${id}`)
            const dta = await res.data
            setGftnm(dta.data)
        } catch (error) {
            console.error(error)
        }
    }
    useEffect(() => getdta, [])

    const setImgFl = (e) => {
        setGftImg(e.target.files[0])
        setImgprvw(URL.createObjectURL(e.target.files[0]));
    }

    // const hndlprvw = async (e) => {
    //     e.preventDefault()
    //     setImgprvw(URL.createObjectURL(gftImg))
    // }

    const hndlsub = async (e) => {
        e.preventDefault()

        try {
            if (gftImg.length===0) {
                window.alert(`Gift Item Photo is Required.`)
            }
            else {
                // console.log(gftImg)
                const confg = {
                    headers: {
                        'Content-Type':'multipart/form-data'
                    }
                }
                const res = await axios.put(`${Server}/gift/edit/photo/`+id, {
                    gft_img:gftImg,
                }, confg)
                const dta = await res.data
                // console.log(dta)
    
                if (dta.statuscode === 220) {
                    window.alert(dta.success)
                    navig(`/admin/gifts/edit/${id}`)
                }
                else {
                    window.alert(dta.error)
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

  return (
    <>
        <div className="wbpg">
            <Header/>

            <div className="main container">
                <div className='content' id='przsec'>
                    <div className="hdr">Change Gift Image</div>
                    <div className="frmsec">
                        {
                            gftnm&&(
                                <form className='frm'>
                                    <div className='frmgrp'>
                                        <label htmlFor="gft_img" className="frmlbl form-label w-25">Gift Image</label>
                                        <input type="file" className="frmfil form-control form-control-lg w-50" name="gft_img" id="gft_img" onChange={setImgFl} />
                                        <div className="frmvald"></div>
                                    </div>
                                    <div className="frmbtngrp gap-5">
                                        {/* <input className='btn btn-outline-primary btn-lg mbtn' type="submit" value="Preview" onClick={hndlprvw} /> */}
                                        <input className='btn btn-outline-success btn-lg mbtn' type="submit" value="Update" onClick={hndlsub} />
                                        <input className='btn btn-outline-secondary btn-lg mbtn' type="reset" value="Refresh" />
                                    </div>
                                </form>
                            )
                        }
                    </div>
                    <div className="crdphto" style={{padding:'1rem'}}>
                        <div className="card" style={{display:'flex',justifyContent:'center',alignItems:'center',width:'50rem',padding:'0rem'}}>
                            <div className="card-body" style={{backgroundColor:'blueviolet',width:'100%'}}>
                                <h5 className="card-title" style={{color:'chartreuse'}}><span style={{color:'azure',fontWeight:'bold'}}>Gift Name:</span>&nbsp;&nbsp;&nbsp;&nbsp;{gftnm&&gftnm.gft_name}</h5>
                            </div>
                            <img src={(imgprvw)?(imgprvw):(gftnm&&gftnm.gft_img)} className="card-img-top" alt="Gift_Photo" style={{width:'75%',padding:'2rem'}} />
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    </>
  )
}

export default Chngphto