import { createSlice } from "@reduxjs/toolkit";

const initState = []

const accSlice = createSlice({
    name: 'Account',
    initialState: initState,
    reducers: {
        loadAcc: (state, action) => {
            state.push(action.payload)
        },
        rmvAcc: (state, action) => {
            const indx = state.findIndex((elm) => elm.item.id === action.payload)
            state.splice(indx,1)
        },
        dltallAcc: (state, action) => {
            state.splice(0,state.length)
        },
    }
})

export const { loadAcc, rmvAcc, dltallAcc } = accSlice.actions
export default accSlice.reducer