import React from 'react'
import Marquee from 'react-fast-marquee'

import lightLeft from '../../images/lckydrwimg/light-01.png'
// import lightLeft from '../../images/lckydrwimg/light.mp4'
// import NoWinnerImage from '../../images/newGifts/LuckyDraw_DiwaliEvent/New folder/side-panel.jpg'
// import NoWinnerImage from '../../images/newGifts/LEFT-BANNER-NO-WINNER.png'
import smlogo from '../../images/lckydrwimg/NewLogo-01.png'
import winner from '../../images/newGifts/winner.png'

import bike from '../../images/newGifts/BIKE.png'
import bmw from '../../images/newGifts/bmw.png'
import galaxyTab from '../../images/newGifts/GALAXY-TAB.png'
import honda from '../../images/newGifts/HONDA.png'
import tv from '../../images/newGifts/LED-TV.png'
import light2fast from '../../images/newGifts/light2fast.gif'



const LeftWinners = ({winners,flagOneLoading}) => {
  const myStyle = {
    '--quantity': (((winners.length)/(winners.length/20)*0.8)*(winners.length)*0.1)+'s', // Use quotes around custom properties (CSS variables)
  }; 
  return (
    <div className="raffleLeft">
         <img src={smlogo} className="smlogo" alt="SM Logo" />
          <div className="leftVideoConteiner">
        
            <div className="contentC">
            {flagOneLoading && <div className="loader">Loading... <img src="https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif" alt='imag'/></div>}
              {winners.length > 0 && <img src={winner} className="headerImg" alt='imag'/>}
              {winners.length > 0 && <p className="totalWinners">Total Winners: {winners.length}</p>}
              {winners && winners.length == 0 && <div className="leftBg"></div>}
              <div className="winnerssListWrap">
                <div className="winnerssList" style={myStyle}>
                  {winners && winners.length > 0 && [...winners].map(item=>{
                    return(
                      <div key={"ww"+item._id} className="winnerItem">
                        {/* {item?.assigned_gift?.prz_itm?.gft_name.toLowerCase().indexOf('bmw') >= 0? <img src={bmw}/>:null}
                        {item?.assigned_gift?.prz_itm?.gft_name.toLowerCase().indexOf('splendor') >= 0? <img src={bike}/>:null}
                        {item?.assigned_gift?.prz_itm?.gft_name.toLowerCase().indexOf('galaxy') >= 0? <img src={galaxyTab}/>:null}
                        {item?.assigned_gift?.prz_itm?.gft_name.toLowerCase().indexOf('hyundai') >= 0? <img src={honda}/>:null}
                        {item?.assigned_gift?.prz_itm?.gft_name.toLowerCase().indexOf('tv') >= 0? <img src={tv}/>:null} */}
                        <div className="winnerMeta">
                          {/* <p><h4>{item?.assigned_gift?.prz_itm?.gft_name}</h4></p> */}
                          {/* <h4>{item?.wnnr_acc?.acc_name}, {item?.wnnr_acc?.acc_unique_code}, {item?.wnnr_tckt?.tckt_no}, {item?.wnnr_acc?.acc_state?.state_name}</h4> */}
                          <div className='hd'>Ticket No.:&nbsp;<span id='wnrtckt'>{item?.wnnr_tckt?.tckt_no}</span></div>
                          <p><div className="hd">Gift:&nbsp;&nbsp;<span id='wnrgft'>{item?.assigned_gift?.prz_itm?.gft_name}</span></div></p>
                          {/* <h6>{item?.wnnr_tckt?.tckt_no}</h6>
                        <p>{item?.wnnr_acc?.acc_state}</p> */}
                        </div>
                        {/* <img src={light2fast} className="light2fast" alt='imag'/> */}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <img src={lightLeft} className="light" alt='imag'/>
        </div>
    </div>
  )
}

export default LeftWinners