import { useState } from 'react'
import '../../styles/Main.css'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import WnnrSttWise from './functions/WnnrSttWise'

const Index = () => {
    const [wnnr, setWnnr] = useState()

  return (
    <>
        <div className="wbpg">
            <Header/>

            <div className="main container">
                <div className="content">
                    <div className="hdng">Dashboard</div>
                    <div className="dtlsec">
                        <div className="chartdta">
                            <WnnrSttWise />
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    </>
  )
}

export default Index