import { useState, useEffect, useRef } from 'react'
import '../../styles/Contest.css'
import { NavLink, useParams, useNavigate } from 'react-router-dom'
import { FaUserPlus, FaCloudUploadAlt, FaUserEdit, FaUserMinus } from 'react-icons/fa'
import { MdCasino, MdGroupRemove } from 'react-icons/md'
import { PiGameControllerFill } from "react-icons/pi";
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import axios from 'axios'
import Server from '../../Server'

const Index = () => {
  const {id} = useParams()
  const [cntst, setCntst] = useState()
  const counterRef = useRef(null)
  const navig = useNavigate()

  const vwgftAllocStat = async () => {
    try {
      const res = await axios.get(`${Server}/contest/edit/updateallocstatfroall`)
      const dta = await res.data
      // console.log(dta)
      // setCntst(dta)
    } catch (error) {
      console.error(error)
    }
  }
  const vwData = async () => {
    try {
      const res = await axios.get(`${Server}/contest/view`)
      const dta = await res.data.data
      // console.log(dta)
      setCntst(dta)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    vwgftAllocStat()
    vwData()
  }, [])

  const hndldlt = async (id) => {
    try {
      if (window.confirm(`Confirm Contest removal ?`)) {
        const res = await axios.delete(`${Server}/contest/remove/`+id)
        const dta = await res.data
        // console.log(dta)
        if (dta) {
          window.alert(`Contest Successfully Removed.`)
          vwData()
        }
      }
      else {
        vwData()
      }
    } catch (error) {
      console.error(error.message)
    }
  }
  // const hndldltall = async (e) => {
  //   e.preventDefault()

  //   try {
  //     if (window.confirm(`Want to Remove all Contest details ?`)) {
  //       const res = await axios.get(`${Server}/winner/remove/all`)
  //       const dta = await res.data
  //       // console.log(dta)
  //       if (dta.statuscode === 220) {
  //         window.alert(dta.success)
  //         vwData()
  //       }
  //       else {
  //         window.alert(dta.error)
  //       }
  //     }
  //     else {
  //       vwData()
  //     }
  //   } catch (error) {
  //     console.error(error)
  //   }
  // }

  const runallcntst = async (e) => {
    let cflg = 1
    e.preventDefault()

    try {
      if (window.confirm(`Confirm to run all Contests ?`)) {
        const res = await axios.get(`${Server}/contest/autorun`)
        const dta = await res.data
        console.log(dta.message);
        // for (let i=0; i<cntst.length; i++,cflg++) {
        //   const res = await axios.get(`${Server}/contest/autorun/`+cntst[i]._id)
        //   const dta = await res.data
        //   console.log(dta.message);
        // }
        if (cflg === cntst.length) {
          const res = await axios.get(`${Server}/contest/autoscreenset`)
          const dta = await res.data
          console.log(dta);
        }
      }
    } catch (error) {
      console.error(error)
    }
  }
  const runautorafl = async (e) => {
    e.preventDefault()
    try {
      const res = await axios.get(`${Server}/contest/autoscreenset`)
      const dta = await res.data
      console.log(dta);
      if (dta.statuscode === 220) {
        window.alert(dta.message)
        vwData()
      }
      else {
        window.alert(dta.message)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const hndlresetall = async (e) => {
    e.preventDefault()

    try {
      if (window.confirm(`Confirm to Reset all Contest data?`)) {
        const res = await axios.get(`${Server}/contest/edit/resetall`)
        const dta = await res.data
        console.log(dta)
        if (dta.statuscode === 220) {
          window.alert(dta.message)
          vwData()
        }
        else {
          window.alert(dta.error)
          vwData()
        }
      }
      else {
        vwData()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
        <div className="wbpg">
          <Header/>

          <div className="main container">
            <div className='content' id='cntstsec'>
              <div className="hdng">Contest Details</div>
              <div className="dtlsec">
                <div className="inf"><span>Here all the Contest details are listed by folllowing table.</span></div>
                <div className="addsec"><span>To Add Contest click here : </span><NavLink to="/admin/contests/add" className='navlnk'><button className='addbtn'><FaUserPlus/></button></NavLink></div>
                <div className="impsec"><span>For Bulk Contests Upload : </span><NavLink to='/admin/contests/upload' className='navlnk'><button className='upldbtn'><FaCloudUploadAlt/></button></NavLink></div>
                <div className="btnsec"><span>Start Autorun All Contests : </span><NavLink className='navlnk'><button className='btn btn-sm btn-info' onClick={runallcntst}>Start All Contest</button></NavLink></div>
                <div className="btnsec"><span>Set Winner Screens & Run Counters : </span><NavLink className='navlnk'><button className='btn btn-sm btn-light' onClick={runautorafl}>Set Screens</button></NavLink></div>
                <div className="btnsec"><span>Start Game Raffle : </span><NavLink className='navlnk' to={`/admin/raffle/auto`}><button className='rnbtn'><PiGameControllerFill/></button></NavLink></div>
                <div className="btnsec"><span>Reset All Winners : </span><NavLink className='navlnk'><button className='rmvbtn' onClick={hndlresetall}><MdGroupRemove/></button></NavLink></div>
              </div>
              <div className="lst">
                <span>Existing Contest lists</span>
                <div className="tbldta">
                  {
                    ((cntst === undefined)||(cntst === null)||(cntst.length === 0)) ? (<div><span>500 ERROR :: Data couldn't be Fetched</span></div>) : (
                      <table className='tbl table table-striped table-hover'>
                        <thead>
                          <tr>
                            <th>Contest Name</th>
                            <th>Contest Level</th>
                            <th>State</th>
                            <th>Start Date</th>
                            <th>Running Validity</th>
                            <th>Booked Tickets</th>
                            <th>Closed Tickets</th>
                            <th>Gift Allocation Status</th>
                            <th>Added By</th>
                            <th style={{backgroundColor:'teal',color:'lightgrey',borderColor:'gray'}}>Start Game</th>
                            <th style={{backgroundColor:'darkslateblue',color:'lightgrey',borderColor:'gray'}}>Edit Data</th>
                            <th style={{backgroundColor:'darkslateblue',color:'lightgrey',borderColor:'gray'}}>Remove Data</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            cntst.map((elm, i) => (
                              <tr key={i}>
                                <td style={{whiteSpace:'nowrap'}}><NavLink to={`/admin/testapi/${elm._id}`}>{elm.cntst_name}</NavLink></td>
                                <td>{elm.cntst_level}</td>
                                <td>{elm.state&&elm.state.state_name}</td>
                                <td>{new Date(elm.cntst_start_dt).toLocaleString()}</td>
                                <td>{elm.cntst_validity}</td>
                                <td>{elm.tckt_booked}</td>
                                <td>{elm.tckt_closed}</td>
                                <td>{elm.gft_alloc_status}</td>
                                <td>{elm.added_by&&(elm.added_by.usr_name||elm.added_by.usr_unam)}</td>
                                <td style={{textAlign:'center',backgroundColor:'#2a3f07'}}>{(elm.tckt_booked>0)&&(elm.gft_alloc_status==='Allocated')&&(<NavLink to={`/admin/raffle/${elm._id}`} className='navlnk'><button className='plybtn'><MdCasino/></button></NavLink>)}</td>
                                <td style={{textAlign:'center'}}><NavLink to={`/admin/testapi/${elm._id}`} className='navlnk'><button className='edtbtn'><FaUserEdit/></button></NavLink></td>
                                <td style={{textAlign:'center'}}><NavLink className='navlnk' onClick={ev => hndldlt(elm._id)}><button className='dltbtn'><FaUserMinus/></button></NavLink></td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    )
                  }
                </div>
              </div>
            </div>
          </div>

          <Footer/>
        </div>
    </>
  )
}

export default Index