import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../../../components/Header'
import Footer from '../../../components/Footer'
import axios from 'axios'
import Server from '../../../Server'

const Addorg = () => {
    const [vl, setVl] = useState()
    let name, value
    const navig = useNavigate()

    const hndlinp = (e) => {
        name = e.target.name
        value = e.target.value
        setVl({...vl, [name]:value})
    }
    const hndlsub = async (e) => {
        e.preventDefault()
        const {org_code, org_desc} = vl

        try {
            const res = await axios.post(`${Server}/orgm/add`, {org_code, org_desc})
            const dta = await res.data
            // console.log(dta.data)

            if (dta.statuscode === 220) {
                window.alert(dta.success)
                navig('/admin/master/org')
            }
            else {
                window.alert(dta.error)
            }
        } catch (error) {
            console.error(error)
        }
    }

  return (
    <>
        <div className="wbpg">
            <Header/>

            <div className="main container">
                <div className='content' id="usrsec">
                    <div className="hdr">Add Organisation</div>
                    <div className="frmsec">
                        <form className='frm'>
                            <div className="frmgrp">
                                <label htmlFor="org_code" className='frmlbl form-label w-25'>Organisation Code</label>
                                <input type="text" className='frminp form-control form-control-lg w-50' name="org_code" id="org_code" onChange={hndlinp} />
                            </div>
                            <div className="frmgrp">
                                <label htmlFor="org_desc" className='frmlbl form-label w-25'>Organisation Description</label>
                                <input type="text" className='frminp form-control form-control-lg w-50' name="org_desc" id="org_desc" onChange={hndlinp} />
                            </div>
                            <div className="frmbtngrp gap-5">
                                <input className='btn btn-outline-primary btn-lg mbtn' type="submit" value="Add" onClick={hndlsub} />
                                <input className='btn btn-outline-secondary btn-lg mbtn' type="reset" value="Refresh" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    </>
  )
}

export default Addorg