import React from 'react'
import smlogo from '../images/lckydrwimg/NewLogo-01.png'

const Logo = () => {
  return (
    <>
        <div className='lgo'><img src={smlogo} alt="SM Logo" /></div>
    </>
  )
}

export default Logo